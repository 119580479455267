* {
	box-sizing: border-box;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
html, body {
	font-size: 18px;
	min-height: 100%;
	height: 100%;
}
body {
	line-height: 1;
	font-family: 'Open Sans', sans-serif;
	color: #025F7B;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
h1, h2, h3, h4, h5, h6 {
	font-family: 'Cardo', serif;
}
h1 {
	font-size: 80px;
}
h2 {
	font-size: 48px;
}

@media (max-width: 720px) {
	h1 {
		font-size: 60px;
	}
	h2 {
		font-size: 40px;
	}
}

@media (max-width: 380px) {
	h1 {
		font-size: 40px;
	}
	h2 {
		font-size: 32px;
	}
}

h3 {
	font-size: 24px;
}
p {
	line-height: 24px;
	margin-bottom: 1em;
}
strong {
	font-weight: 600;
}
em {
	font-style: italic;
}
#root {
	height: 100%;
}
.parallax-outer {
	position: relative;
	z-index: 1;
}